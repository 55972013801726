<template>
  <div class="table-bg p-3">
    <div class="table-light-bg">
      <table class>
        <thead>
          <tr>
            <td>Item</td>
            <td>Quantity</td>
            <td>Total</td>
          </tr>
        </thead>
        <!-- {{fnbList}} -->
        <tbody>
          <tr v-for="(fnbitem , fnbitemKey) in fnbList" :key="fnbitemKey">
            <td class="align-text-center">{{fnbitem.item_name}}</td>
            <td class="align-text-center">{{fnbitem.quantity}}</td>
            <td
              class="align-text-center"
            >{{fnbitem.fp_total_amount * fnbitem.quantity}} {{currCode}}</td>
          </tr>

          <tr>
            <td>
              <h6 class="mb-0 font-weight-bold">Sub Total :</h6>
            </td>
            <td></td>
            <td>
              <h6
                class="mb-0 font-weight-bold"
              >{{fnbList && fnbList.length > 0 ? fnbList.reduce((ac, x) => ac += x.fp_amount * x.quantity | RoundOff, 0) : "-"}} {{currCode}}</h6>
            </td>
          </tr>

          <tr
            v-if="charges.length > 0"
            v-for="(chargesitem, chargesitemKey) in charges"
            :key="chargesitemKey"
          >
            <td>
              <h6 class="mb-0 font-weight-bold">{{chargesitem.ct_name | uppercase}}</h6>
            </td>
            <td></td>
            <td>
              <h6 class="mb-0 font-weight-bold">{{ chargesitem.totalTax | RoundOff }} {{currCode}}</h6>
            </td>
          </tr>

          <tr>
            <td>
              <h5 class="mb-0 font-weight-bold">Gross Total :</h5>
            </td>
            <td></td>
            <td>
              <h5
                class="mb-0 font-weight-bold"
              >{{fnbList && fnbList.length > 0 ? fnbList.reduce((ac, x) => ac += x.fp_total_amount * x.quantity, 0) : "-"}} {{currCode}}</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // setPosFnB: { type: Function }
    fnbList: {
      type: Array
    },
    fnbtotalamount: {
      type: Number
    },
    currCode: {
      type: String
    },
    charges: {
      type: Array
    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
}

table,
th,
td {
  border-bottom: 3px solid #ffffff;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.table-bg {
  background-color: #3876d2;
  height: 100%;
}

.table-light-bg {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 20px;
}
</style>